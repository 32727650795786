import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'
import GeometricBackgroundImage from '../images/geometric_background.svg'
import WavesImage from '../images/MP_Header.svg'

const Button = styled(BootstrapButton)`
border-radius: 20px !important;
`
export const BlueBackground = styled.div`
clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);
padding-top: 70px;
@media (max-width: 768px) {
    clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    padding-top: 30px;
}
color: var(--white);
a {
    color: var(--white) !important;
}
background: radial-gradient(farthest-corner at 100px 100px, var(--dark), var(--success));
`

export const TestimonialsBackground = styled.div`
clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
padding-top: 70px;
padding-bottom: 70px;

@media (max-width: 768px) {
    clip-path: polygon(0% 0%, 100% 3%, 100% 97%, 0% 100%);
    padding-top: 60px;
    padding-bottom: 60px;
}
background-color: var(--secondary);
`

export const BlueBackgroundHeader = styled.div`
color: var(--white);
a.nav-link {
    color: var(--white) !important;
}
background: url('${WavesImage}'), radial-gradient(farthest-corner at 100px 100px, var(--dark), var(--success));
background-position: right;
background-repeat: no-repeat;
background-size: contain;
@media (max-width: 768px) {
    background: radial-gradient(farthest-corner at 100px 100px, var(--dark), var(--success));
}
`
export const ContactUsButton = styled(({ className = '', ...props }) => <Button variant="outline-light" className={`btn-lg btn-block ${className}`} {...props} />)`
color: white !important;
font-weight: 700px;
&:hover {
    color: var(--dark) !important
}
`
export const SocialMediaIcon = styled(FontAwesomeIcon)`
width: 50px !important;
height: 50px !important;
color: var(--white) !important;
padding: 2px 0px;
`
export const FadedTextH1 = styled.h1`
opacity: 0.8;
font-size: 1.5em;
`
export const FadedText = styled.div`
opacity: 0.8;
`
export const ButtonStyledSecondary = styled(({ className = '', ...props }) => <Button variant="outline-light" className={`${className} btn-block`} {...props} />)`
font-weight: bold! important;
color: white !important;
opacity: 1 important;

&:hover {
    color: var(--dark) !important
}
`

export const GeometricBackground = styled.div`
background: #F5F5F5 url('${GeometricBackgroundImage}') no-repeat center center;
background-size: cover;
`
