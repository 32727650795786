/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import mpLogo from '../images/mountainpass-logo-1200x1200.jpg'

function SEO ({ Content, meta, lang }) {
  console.log('content', Content)

  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [metaImage, setMetaImage] = useState('')
  const [metaUrl, setMetaUrl] = useState('')
  const [metaAuthor, setMetaAuthor] = useState('')
  const [metaKeywords, setMetaKeywords] = useState('')

  // const metaTitle = Content.SiteMeta.title
  // const metaDescription = Content.SiteMeta.description
  // const metaImage = Content.SiteMeta.url + mpLogo
  // const metaUrl = Content.SiteMeta.url
  // const metaAuthor = Content.SiteMeta.author
  // const metaKeywords = Content.SiteMeta.metaKeywords
  const metaColour = `#00239c`
  const metaFbAppId = '902316943603565'

  useEffect(() => {
    console.log('triggered', Content)
    setMetaTitle(Content.SiteMeta.title)
    setMetaDescription(Content.SiteMeta.description)
    setMetaImage(Content.SiteMeta.url + mpLogo)
    setMetaUrl(Content.SiteMeta.url)
    setMetaAuthor(Content.SiteMeta.author)
    setMetaKeywords(Content.SiteMeta.metaKeywords)
  }, [Content])

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `title`,
          content: metaTitle
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `image`,
          content: metaImage
        },
        {
          name: `url`,
          content: metaUrl
        },
        {
          name: `author`,
          content: metaAuthor
        },
        {
          name: `keywords`,
          content: metaKeywords
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:site_name`,
          content: metaTitle
        },
        {
          property: `og:url`,
          content: metaUrl
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: metaImage
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: metaAuthor
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: metaImage
        },
        {
          name: `twitter:url`,
          content: metaUrl
        },
        {
          property: `fb:app_id`,
          content: metaFbAppId
        },
        {
          name: `theme-color`,
          content: metaColour
        },
        {
          name: 'msapplication-navbutton-color',
          content: metaColour
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: metaColour
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  Content: { SiteMeta: {} },
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  Content: PropTypes.object
}

export default SEO
